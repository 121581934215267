import { useState, useEffect } from "react"

export const useAudio = () => {
  const [audio, setAudio] = useState(null)
  const [playingUrl, setPlayingUrl] = useState(false)

  const startAudio = url => {
    if (audio) {
      audio.pause()
    }
    const audioObject = new Audio(url)
    setAudio(audioObject)
    setPlayingUrl(url)

    audioObject.addEventListener("ended", event => {
      setAudio(null)
      setPlayingUrl(false)
    })
  }

  useEffect(() => {
    if (!audio) {
      return
    }
    audio.play()
  }, [audio])

  return [playingUrl, startAudio]
}
