import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

import { useSounds } from "../hooks/use-sounds"
import { useSoundTags } from "../hooks/use-sound-tags"
import { useAudio } from "../hooks/use-audio"
import { MdPlayCircleOutline, MdPlayCircleFilled } from "react-icons/md"

const PlayIcon = styled.span`
  font-size: 25px;
  line-height: 25px;
  height: 25px;
  width: 25px;
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:hover,
  &.active {
    color: #fb251a;
  }
`

const IndexPage = () => {
  const soundList = useSounds()
  const soundTags = useSoundTags()
  const [playingUrl, startAudio] = useAudio()

  return (
    <Layout title="Sounds">
      <SEO title="Sounds" keywords={[`sonos`, `audio`, `notifications`]} />

      <table>
        <thead>
          <tr>
            <th width="1" />
            <th>Slug</th>
            <th>Seconds</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          {soundList.map((sound, index) => (
            <tr key={index}>
              <td>
                <PlayIcon
                  onClick={() => startAudio(sound.audioUrl)}
                  className={playingUrl === sound.audioUrl ? "active" : ""}
                >
                  {playingUrl === sound.audioUrl ? (
                    <MdPlayCircleFilled />
                  ) : (
                    <MdPlayCircleOutline />
                  )}
                </PlayIcon>
              </td>
              <td>
                <code>{sound.slug}</code>
              </td>
              <td>{sound.length.toFixed(2)}</td>
              <td>
                {sound.tags
                  .map(tagSlug => {
                    return soundTags.filter(tag => {
                      return tagSlug === tag.tag
                    })[0].name
                  })
                  .join(", ")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  )
}

export default IndexPage
