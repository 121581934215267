import { useStaticQuery, graphql } from "gatsby"

export const useSounds = () => {
  const data = useStaticQuery(graphql`
    query QuerySounds {
      allQuakSounds(limit: 1000, filter: { id: { ne: "dummy" } }) {
        nodes {
          name
          slug
          length
          tags
          audioUrl
        }
      }
    }
  `)

  return data.allQuakSounds.nodes
}
