import { useStaticQuery, graphql } from "gatsby"

export const useSoundTags = () => {
  const data = useStaticQuery(graphql`
    query QuerySoundTags {
      allQuakSoundTags(limit: 1000, filter: { id: { ne: "dummy" } }) {
        nodes {
          tag
          name
        }
      }
    }
  `)

  return data.allQuakSoundTags.nodes
}
